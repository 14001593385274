<template>
  <full-view
    :forth-button="initAccount"
    :back-button="!initAccount"
    :title="$t('app.route.regions')"
  >
    <template v-slot:toolbar>
      <v-stepper v-if="initAccount" v-model="step" class="secondary" style="box-shadow: none !important;">
        <v-stepper-header>
          <v-stepper-step :complete="!!getCurrentUser.regionId" step="1" :color="step > 1 ? 'secondary darken-3': ''">
            <div class="v-stepper__label" :style="step > 1 ? 'color: #4d977b;': ''">Select Your Region</div>
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="2">
            Add Your Address
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
    </template>

    <v-card flat>
      <v-card-text class="overflow-y-auto px-0 pt-0" :style="getViewHeightStyle(36)">
        <p class="px-5 pt-3 body-2" v-if="!getCurrentUser.regionId">
          You haven't selected your region yet, please select one from the list:
        </p>

        <v-list class="pt-0" two-line>
          <div v-for="(region, i) in getRegions" :key="i">
            <v-list-item
              :class="i % 2 === 0 ? 'white' : 'grey lighten-5'"
              @click="selectRegion(region.id)"
            >
              <v-list-item-avatar>
                <v-img v-if="!!region.countryShort" :src="`https://flagcdn.com/${region.countryShort}.svg`"></v-img>
              <!-- <v-img v-if="!!item.countryShort" alt="Logo" :src="getServerFile(item.logo)"></v-img> -->
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="region.name" />
              </v-list-item-content>
              <v-list-item-action class="px-3 pt-3">
                <v-icon large color="primary">
                  {{
                    region.id === getCurrentUser.regionId
                      ? "mdi-radiobox-marked"
                      : "mdi-radiobox-blank"
                  }}
                </v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-divider />
            <!-- <v-divider
              v-if="
                notEmptyArray(getRegions) && i !== getRegions.length - 1
              "
            /> -->
          </div>
        </v-list>
      </v-card-text>
    </v-card>
  </full-view>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FullView from "@/common/layouts/FullView";

export default {
  name: "Regions",
  components: { FullView },
  data() {
    return {
      step: 1,
      initAccount: null,
    };
  },
  mounted() {
    this.initAccount = !this.getCurrentUser.regionId;
    this.loadRegions();
  },
  computed: {
    ...mapGetters("auth", ["getCurrentUser"]),
    ...mapGetters("regions", ["getRegions"]),
  },
  methods: {
    ...mapActions("regions", ["loadRegions"]),
    ...mapActions("auth", ["selectUserRegion"]),
    ...mapActions("settings", ["selectRegion"]),

    selectRegion(id) {
      this.selectUserRegion(id).then(() => {
        this.step = 2;
      });
    },
  },
};
</script>
