import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('full-view',{attrs:{"forth-button":_vm.initAccount,"back-button":!_vm.initAccount,"title":_vm.$t('app.route.regions')},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [(_vm.initAccount)?_c(VStepper,{staticClass:"secondary",staticStyle:{"box-shadow":"none !important"},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c(VStepperHeader,[_c(VStepperStep,{attrs:{"complete":!!_vm.getCurrentUser.regionId,"step":"1","color":_vm.step > 1 ? 'secondary darken-3': ''}},[_c('div',{staticClass:"v-stepper__label",style:(_vm.step > 1 ? 'color: #4d977b;': '')},[_vm._v("Select Your Region")])]),_c(VDivider),_c(VStepperStep,{attrs:{"step":"2"}},[_vm._v(" Add Your Address ")])],1)],1):_vm._e()]},proxy:true}])},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,{staticClass:"overflow-y-auto px-0 pt-0",style:(_vm.getViewHeightStyle(36))},[(!_vm.getCurrentUser.regionId)?_c('p',{staticClass:"px-5 pt-3 body-2"},[_vm._v(" You haven't selected your region yet, please select one from the list: ")]):_vm._e(),_c(VList,{staticClass:"pt-0",attrs:{"two-line":""}},_vm._l((_vm.getRegions),function(region,i){return _c('div',{key:i},[_c(VListItem,{class:i % 2 === 0 ? 'white' : 'grey lighten-5',on:{"click":function($event){return _vm.selectRegion(region.id)}}},[_c(VListItemAvatar,[(!!region.countryShort)?_c(VImg,{attrs:{"src":("https://flagcdn.com/" + (region.countryShort) + ".svg")}}):_vm._e()],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(region.name)}})],1),_c(VListItemAction,{staticClass:"px-3 pt-3"},[_c(VIcon,{attrs:{"large":"","color":"primary"}},[_vm._v(" "+_vm._s(region.id === _vm.getCurrentUser.regionId ? "mdi-radiobox-marked" : "mdi-radiobox-blank")+" ")])],1)],1),_c(VDivider)],1)}),0)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }